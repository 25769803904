import React from "react"

import { Link } from "gatsby"

import type { Node } from "@/types"

import Author from "./Author"
import Comments from "./Comments"
import Content from "./Content"
import Meta from "./Meta"
import Tags from "./Tags"

import * as styles from "./Post.module.scss"

interface Props {
  post: Node
}

const Post: React.FC<Props> = ({ post }: Props) => {
  const { html } = post
  const { tagSlugs, slug } = post.fields
  const { tags, title, date } = post.frontmatter

  return (
    <div className={styles.post}>
      <Link className={styles.button} to="/">
        All Articles
      </Link>

      <div className={styles.content}>
        <Content body={html} title={title} />
      </div>

      <div className={styles.footer}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
      </div>

      <div className={styles.comments}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  )
}

export default Post
